<template>
  <div>  
    <CRow>
      <CCol lg="12">
        <CButton color="info" size="sm" @click="link('home')" style="float: right;">Inicio</CButton>
        <h2>INFORMACIÓN DEL CLIENTE</h2>
      </CCol>
    </CRow> 
    <CRow>
      <CCol :md="12">
        <CCard>
          <CRow class="g-0">
            <CCol :md="2" style="padding: 5px 0px 5px 20px" v-if="customer.logo">
              <div style="min-height: 200px; max-height: 200px;">
                <img :src="customer.logo" style="height: 200px;" />
              </div>
                <!-- <img :src="customer.logo" width="200" height="200"/> -->                
            </CCol>
            <CCol :md="customer.logo ? 10 : 12">
              <CCardBody style="min-height: 213px; max-height: 220px;">
                <CCardTitle style="text-align:center; float: right;"><strong>{{customer.name}}</strong></CCardTitle>
                <CRow>
                  <CCol :md="12"><br />
                    <strong>Razón Social:</strong>
                    {{customer.razon_social ? customer.razon_social : 'N / D'}}<br /><br />
                  </CCol>
                  <CCol :md="12">
                    <strong>¿Que busca?</strong>
                  </CCol>
                  <CCol :md="12">
                    {{customer.objetives ? customer.objetives :  'N / D'}}
                  </CCol>
                  <CCol :md="12">
                    <br />
                    <strong>Grupo WhatsApp: </strong> 
                    <a v-if="customer.contact" :href="customer.contact">{{customer.contact}}</a>
                    <span v-else>N / D</span>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCol>
          </CRow>
        </CCard>   
      </CCol>
      <CCol :md="6">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-grid"/> Personas de Contacto
              </slot>
            </CCardHeader>
            <CCardBody style="min-height: 250px;">
              <CDataTable v-if="customer.people.length > 0"
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="customer.people"
                  :fields="contacts_fields"
                  :items-per-page="5"
                  pagination
                  class="table-customers"
                >
                  <template #contact_name="{item}"><td class="w150 text-center">{{ item.name }}</td></template>
                  <template #contact_phone="{item}"><td class="w150 text-center">{{ item.phone }}</td></template> 
              </CDataTable>
              <div v-else style="text-align: center;"><br /><br /><br /><br />NO HAY PERSONAS DE CONTACTO REGISTRADAS</div>
            </CCardBody>
          </CCard>
      </CCol>
      <CCol :md="6">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-grid"/> Cadenas
              </slot>
            </CCardHeader>
            <CCardBody style="min-height: 250px;">
              <CDataTable v-if="stores.length > 0"
                :hover="hover"
                :striped="striped"
                :border="border"
                :small="small"
                :fixed="fixed"
                :items="stores"
                :fields="store_fields"
                :items-per-page="3"
                pagination
                class="table-cadena"
              >
                <template #store="{item}"><td class="w150 text-center">{{ item.store_name }}</td></template>
                <template #supplier_number="{item}"><td class="w150 text-center">{{ item.supplier_number }}</td></template>
                <template #departament="{item}"><td class="w200 text-center">{{ item.departament }}</td></template>   
                <template #promotion="{item}">
                  <td class="w100 text-center">
                    <CButton style="width: 30px; padding: 0px;" v-if="item.promotion != '' && item.promotion != null" v-c-tooltip="{content: item.promotion}" color="secondary">
                      <CIcon name="cilCommentBubble" style="width: 20px; height: 20px;"/>             
                    </CButton>
                    <CButton color="info" size="sm" style="margin-left: 10px;" @click="openPromotion(item)">{{item.promotion != '' ? 'Editar' : 'Registrar'}}</CButton>
                  </td>
                </template>   
              </CDataTable>
              <div v-else style="text-align: center;"><br /><br /><br /><br />NO HAY CADENAS REGISTRADAS EN LOS CONTRATOS DE ESTE CLIENTE</div>
            </CCardBody>
          </CCard>  
      </CCol>
    </CRow>  
    <CRow>
      <CCol :md="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Productos
            </slot>
          </CCardHeader>
          <CCardBody style="min-height: 510px;">
            <CDataTable
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="products"
              :fields="fields"
              :items-per-page="5"
              pagination
              class="table-customers"
            >
              <!-- <template #imagen="{item}"><td class="w150 text-right">{{ item.image}}</td></template> -->
              <template #imagen="{item}"><td class="w150 text-center">
                <div v-if="item.image" style="min-height: 100px; max-height: 100px;">
                    <img :src="item.image" style="height: 100px;" />
                </div>
                <div v-else style="min-height: 30px; max-height: 30px;"> - </div>
              </td>
              </template>
              <template #store="{item}"><td class="w150 text-center">{{ item.store_name }}</td></template>
              <template #supplier_number="{item}"><td class="w150 text-center">{{ item.supplier_number }}</td></template>
              <template #departament="{item}"><td class="w200 text-center">{{ item.departament }}</td></template>      
              <template #sku="{item}"><td class="w150 text-center">{{ item.code}}</td></template>
              <template #descripcion="{item}"><td class="text-left">{{ item.name  }}</td></template>
            </CDataTable>
          </CCardBody>
        </CCard>
        
      </CCol>
    </CRow>
    <PromotionModal ref="promotionModal" @reload="reloadBrands"></PromotionModal>
  </div>  
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/kam';
import moment from 'moment';
import PromotionModal from './modals/PromotionModal.vue';



export default {
  name: 'KamCustomer',
  components: {
    PromotionModal
  },
  data () {
    return {      
      page: 'kamCustomer',
      customer : {
        people: [],
      },
      products : [],
      stores : [],
      id: 0,
      hover: true,
      striped: false,
      border: true,
      small: false,
      fixed: false,
      dark: true,
      fields: [
        {key: 'imagen', label: 'Imagen'},
        {key: 'sku', label: 'SKU'},
        {key: 'descripcion', label: 'Descripción'},
      ],
      store_fields: [
        {key: 'store', label: 'Cadena'},
        {key: 'supplier_number', label: 'No. Proveedor'},
        {key: 'departament', label: 'Departamento'},
        {key: 'promotion', label: 'Promoción'},
      ],
      contacts_fields: [
        {key: 'contact_name', label: 'Nombre'},
        {key: 'contact_phone', label: 'Número de Celular'}
      ],
    }
  },
  mounted: async function() {
    this.loading();
    this.id = await this.$route.params.id;
      let response_customer = await ws.showCustomer(this.id);
      if(response_customer.type == "success"){
        this.customer = response_customer.data;
      }
      let response_products = await ws.getProductCustomerList(this.id,[]);
      if(response_products.type == "success"){
        this.products = response_products.data;
      }
      let response_stores = await ws.getStoreCustomerList(this.id);
      if(response_stores.type == "success"){
        this.stores = response_stores.data;
      }
    this.loaded();
  },
  methods: {     
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    link(type, item){
      switch (type) {
        case "home":
            this.$router.push('/welcome');
          break;
      
        default:
          break;
      }      
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    async openPromotion (item) {
      this.$refs.promotionModal.show(item);
    },
    async reloadBrands () {
      this.loading();
        let response_stores = await ws.getStoreCustomerList(this.id);
        if(response_stores.type == "success"){
          this.stores = response_stores.data;
        }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.table-cadena table {
  font-size: 12px !important;
}
.table-cadena table td{
  padding: 3px;
}
</style>
