<template>
    <CModal title="Promoción" :color="colorModal" :show.sync="confirmModal">
        <p style="font-weight: bold;">¿Deseas Registrar/Editar una Promoción ({{name}})?</p>
        <CTextarea label="Promoción" v-model="promotion"></CTextarea>
        <template #header>
          <h5 class="modal-title">{{ name }} - Promoción </h5>
          <CButtonClose @click="confirmModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="confirmModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="deletePromotion" color="danger">Eliminar</CButton>
          <CButton @click="storePromotion" color="success">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../../store'
import ws from '../../../services/kam';

export default {
  name: 'PromotionModal',
  props: {

  },
  data () {
    return {
      confirmModal: false,
      colorModal: 'info',
      promotion: "",
      id: "",
      name: "",
    }
  },
  methods: {
    show (item) {
      this.confirmModal = true;
      this.id = item.customer_store_id;
      this.promotion = item.promotion;
      this.name = item.store_name;
    },
    async storePromotion () {
      this.loading();
        let response = await ws.storePromotionCustomer(this.id,this.promotion);
        if(response.type == "success"){
          this.loaded();
          this.$emit("reload");
          this.confirmModal = false;
        }                
      this.loaded();
    },
    async deletePromotion () {
      this.loading();
        let response = await ws.storePromotionCustomer(this.id,"");
        if(response.type == "success"){
          this.loaded();
          this.$emit("reload");
          this.confirmModal = false;
        }                
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>